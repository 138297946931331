import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';


const AllArticles = ({data}) => {
    const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    const apiKey = process.env.REACT_APP_API_KEY_NEW;
    const url = `${serverBaseUrl}/content/tree/Articles`;
    const imageUrl = process.env.REACT_APP_IMAGE_URL;
  
    const [blogArticles, setBlogArticles] = useState([]);

    console.log('DATALLARTICLES', data)
  
    console.log(blogArticles)
  
  useEffect(() => {
    const fetchData = async () => {
  
      try {
        const response = await axios.get(url, {
          headers: {
            "api-key": apiKey
          }
        });
        console.log(response);
        setBlogArticles(response.data)
      } catch (error) {
        console.error("There was an error fetching the data", error);
      }  
    };
  
    fetchData();
  }, [])

  /*function extractTextFromHtml(htmlString) {
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = htmlString;
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }*/

  return (
    <div className='px-10 py-10 pt-10 pb-10 bg-slate-100'>
    <h2 id='articoli' className='text-3xl text-[#0f95d4] text-center font-bold mb-5'>{data.mainTitle}</h2>
    <div className='flex flex-col md:flex-row md:flex-wrap justify-center gap-5'>
        {blogArticles && blogArticles.map((article) => (
            <>
        <div className='flex flex-col bg-white rounded-lg shadow-sm md:w-1/5'>
            <div>
                <img src={`${imageUrl}${article.immagine.path}`} className='w-full h-60 object-cover rounded-t-lg' alt="Preview Article"/>

            </div>
            <div key={article._id} className='flex flex-col items-center p-2'>
                <h3 className='font-bold text-sm text-center pt-3'>{article.titolo}</h3>
                <p className='font-light text-xs text-center text-stone-500 pt-3 pb-2' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(article.anteprimaTesto)}}></p>
                <Link to={`/blog/${article.slug}`}>
                    <button className="rounded-3xl py-2 border border-[#0f95d4] bg-[#0f95d4] text-white font-light w-48 h-10 hover:bg-white hover:text-[#0f95d4] my-2">{data.buttontext1}</button>
                </Link>
            </div>
        </div>
            </>
        ))}
    </div>
 </div>
  )
}

export default AllArticles