import React from 'react'
import DOMPurify from 'dompurify';

const Header2 = ({data}) => {
  /*function extractTextFromHtml(htmlString) {
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = htmlString;
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  const dataBody = extractTextFromHtml(data.body)*/

  const cleanHtml = DOMPurify.sanitize(data.body);

  return (
    <div className='background-gradiente-blu text-white pb-10 h-full flex flex- col justify-center items-center'>
    <div className='py-10 text-center flex flex-col justify-center items-center w-1/2'>
      <h1 className='text-5xl font-bold'>{data.mainTitle}</h1>
      <p cclassName='mt-4 px-5 text-md text-center' dangerouslySetInnerHTML={{__html: cleanHtml}}></p>
      <div className='flex flex-col md:flex-row items-center justify-center mt-10 gap-5 mb-10'>
      <button className='rounded-3xl py-2 border border-white font-light w-40 h-10 text-xs md:text-sm md:w-48 md:h-10 bg-white text-[#0f95d4]'><a href="#contact-section">{data.buttontext1}</a></button>
      <button className='rounded-3xl py-2 border border-white text-white w-40 h-10 text-xs md:text-sm  font-light md:w-48 md:h-10 '><a href="#servizi">{data.buttontext2}</a></button>
      </div>
    </div>
  </div>
  )
}

export default Header2