import React, {useState, useEffect} from 'react';
import {HashRouter, BrowserRouter, Routes, Route} from 'react-router-dom';
import axios from 'axios';
import BlogPostPage from './pages/BlogPostPage';
import DynamicPage from './pages/DynamicPage';

function App() {
  const [pageData, setPageData] = useState(null);
  const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const apiKey = process.env.REACT_APP_API_KEY_NEW;
  const url = `${serverBaseUrl}/content/tree/pagecomponents`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url, {
          headers: {
            "api-key": apiKey
          }
        });
        setPageData(response.data);
        console.log('PAGE DATAAA', response.data)
      } catch (error) {
        console.error("There was an error fetching the data", error);
        console.log(error)
      }
    };

    fetchData();
  }, [serverBaseUrl, apiKey, url]);

  return (
  <>
  {/*<BrowserRouter>
    <Routes>
    {pageData && pageData.map((page, index) => {
          if (page.isDynamic) {
            return (
              <Route 
                key={index}
                exact path={`/blog/:slugPost`} 
                element={<BlogPostPage />} 
              />
            );
          } else {
            return (
              <Route 
                key={index} 
                exact path={page.slug} 
                element={<DynamicPage page={page} />} 
              />
            );
          }
        })}
    </Routes>
      </BrowserRouter>*/}
      <BrowserRouter>
      <Routes>
        {pageData && pageData.map((page, index) => (
          <Route 
            key={index}
            exact
            path={page.isDynamic ? `/blog/:slugPost` : page.slug}
            element={<DynamicPage page={page} />}
          />
        ))}
      </Routes>
    </BrowserRouter>
  </>
  );
}

export default App;
