import React, {useState, useEffect} from 'react';
import { useParams  } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';

const SinglePost2 = () => {
    const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    const imageUrl = process.env.REACT_APP_IMAGE_URL;
    const apiKey = process.env.REACT_APP_API_KEY_NEW;
    const {slugPost} = useParams();
    const url = `${serverBaseUrl}/content/tree/Articles`;
    const [blogPost, setBlogPost] = useState({});
    console.log('IL BLOG POST', blogPost)
    console.log(slugPost)

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(url, {
              headers: {
                "api-key": apiKey
              }
            });
            console.log('RESPONSE',response.data)
            const blogPostTarget = response.data.find(post => post.slug === slugPost);
            setBlogPost(blogPostTarget);
          } catch (error) {
            console.error("There was an error fetching the data", error);
          }
        };
  
        fetchData();
        window.scrollTo(0,  0);
      }, [slugPost]);

      /*function extractTextFromHtml(htmlString) {
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = htmlString;
        return tempDivElement.textContent || tempDivElement.innerText || "";
      }*/
  
    return (
      <div className='flex flex-col justify-center items-center bg-white'>
        <div className='md:w-1/2 mt-5'>
          <img 
            src={`${imageUrl}${blogPost.immagine?.path}`} 
            className='w-full object-cover rounded-xl' 
            alt={blogPost.titolo}
          />
        </div>
        <span className='italic text-sm pt-2'>{blogPost.data}</span>
        <div key={blogPost._id} className='p-4 md:w-1/2'>
          <h3 className='font-bold text-2xl md:text-3xl text-center mb-2'>{blogPost.titolo}</h3>
          <p className='text-base md:text-lg text-stone-500' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(blogPost.content)}}></p>
        </div>
      </div>
    );
  };
  
  export default SinglePost2;
  