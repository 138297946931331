import React from 'react';
import DOMPurify from 'dompurify';

const Highlights2 = ({data}) => {
  /*function extractTextFromHtml(htmlString) {
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = htmlString;
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }*/

    return (
        <div className='text-white w-screen background-gradiente-blu mt-10'>
          <div className='flex flex-col md:flex-row justify-center gap-3 pb-10 pt-10 px-10'>
            {data._children.map((highlight, index) => (
              <div key={index} className='flex flex-row align-top pt-5 gap-3'>
                <div className='w-20 h-6 md:w-20 md:h-6 rounded-xl bg-white mx-2'></div>
                <div className='flex flex-col'>
                  <h2 className='font-bold text-lg w-1/2'>{highlight.highlightsTitle}</h2>
                  <p className='text-sm pt-3 max-w-xs' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(highlight.highlightsBody)}}></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
      
}

export default Highlights2