import React from 'react';
import DynamicComponent from '../components/DynamicComponent';

const DynamicPage = ({ page }) => {
  return (
    <div>
      {page._children.map((component, index) => (
        <DynamicComponent key={index} componentData={component} />
      ))}
    </div>
  );
};

export default DynamicPage;
