import React from 'react';
import DOMPurify from 'dompurify';

const Hero2 = ({data}) => {
    const imageUrl = process.env.REACT_APP_IMAGE_URL;

    /*function extractTextFromHtml(htmlString) {
      const tempDivElement = document.createElement("div");
      tempDivElement.innerHTML = htmlString;
      return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    const dataBody = extractTextFromHtml(data.body)*/
    const cleanHtml = DOMPurify.sanitize(data.body);

    return (
        <>
          <div className='flex flex-col md:flex-row justify-around pt-10 pb-10 px-10 mt-5'>
            <img className='w-1/2 md:w-1/3 object-cover' src={`${imageUrl}${data.image?.path}`} alt="Immagine hero" />
            <div className='flex flex-col my-5 w-1/2'>
              <h1 className='font-bold text-3xl'>{data.mainTitle}</h1>
              <p className='light text-md pt-5 text-stone-500' dangerouslySetInnerHTML={{__html: cleanHtml}}></p>
              <div className='flex flex-row align-top pt-5 gap-3'>
                <div className='flex flex-col'>
                  {data._children && data._children.map((secondaryHero, index) => (
                    <React.Fragment key={index}>
                         <div className='flex flex-row align-top pt-5 gap-3'>
                            <div className='w-40 h-6 md:w-20 md:h-6 rounded-xl bg-[#0f95d4] mx2'></div>
                            <div className='flex flex-col'>
                            <h2 className='font-bold text-lg w-1/2'>{secondaryHero.secondaryTitle}</h2>
                            <p className='text-sm pt-3 max-w-xs text-stone-500' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(secondaryHero.secondaryBody)}}></p>
                            </div>
                        </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      );
      
}

export default Hero2