import React, {useState} from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact2 = ({data}) => {
  const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const apiKey = process.env.REACT_APP_API_KEY_NEW;
  const url = `${serverBaseUrl}/content/item/contatto`;
  const [verify, setVerify] = useState(false);
  console.log(verify);
  const [resultMessage, setResultMessage] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);

  const recaptchaKey = process.env.REACT_APP_RECAPTCHA

  const onChange = (value) => {
    console.log('ReCapatcha value', value)
    setVerify(true)
  }

  const [message, setMessage] = useState({
    "data": {
      "nome": "",
      "email": "",
      "oggetto": "",
      "messaggio": ""
    }
  })

  const handleMessageInput = (event) => {
    const { name, value } = event.target;
    setMessage(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value
      }
    }));
  };

  console.log('MESSAGGIO',message)

  const handleSubmit = async (event) =>{
    event.preventDefault()
    setResultMessage('')

    if(verify){
      try {
        setSendingMessage(true)
        const response = await axios.post(url, message, {
          headers: {
            "api-key": apiKey
          }
        });
        console.log(response);
        if(response.status === 200){
          setSendingMessage(false)
          setMessage({
            "data": {
              "nome": "",
              "email": "",
              "oggetto": "",
              "messaggio": ""
            }
          })
          setResultMessage('Messaggio inviato con successo ✅')
        } else {
          setSendingMessage(false)
          setResultMessage('Errore, messaggio non inviato ❌')
        }
      } catch (error) {
        console.error("There was an error sending message", error);
        setSendingMessage(false)
        setResultMessage('Errore, messaggio non inviato ❌')
      }
    } else{
      setSendingMessage(false)
      setResultMessage('Prima di inviare il messaggio devi risolvere il recaptcha ⚠️')
    }
  }
  return (
    <div className="background-gradiente-blu min-h-screen pt-5 flex flex-col justify-center items-center">
        <h2 className='text-xl md:text-3xl text-white text-center font-bold mb-5'>{data.mainTitle}</h2>
      <form id='contact-section' className="md:w-full w-sm md:max-w-lg p-8 space-y-4 bg-white rounded-lg shadow-md" onSubmit={handleSubmit}>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="name">
              Nome
            </label>
            <input required className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="name" type="text" placeholder="Mario Rossi" name="nome" value={message.data.nome} onChange={handleMessageInput} />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="email">
              Indirizzo Email
            </label>
            <input required className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="email" placeholder="email@example.com" name="email" value={message.data.email} onChange={handleMessageInput} />
          </div>
        </div>
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="subject">
            Oggetto
          </label>
          <input required className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="subject" type="text" placeholder="Oggetto del messaggio" name="oggetto" value={message.data.oggetto} onChange={handleMessageInput} />
        </div>
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="message">
            Messaggio
          </label>
          <textarea required className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="message" placeholder="Scrivi qui il tuo messaggio" rows="3" name="messaggio" value={message.data.messaggio} onChange={handleMessageInput}></textarea>
        </div>
        <div className="w-full px-3 text-center">
        <ReCAPTCHA sitekey={recaptchaKey} onChange={onChange}/>
          <button type="submit" className={`rounded-3xl py-2 border text-white font-light w-48 h-10 mt-4 ${verify ? 'border border-[#0f95d4] bg-[#0f95d4] hover:bg-white hover:text-[#0f95d4]' : 'bg-gray-400 border-gray-400 cursor-not-allowed'}`} disabled={!verify}>
            Invia
          </button>
        </div>
        {!sendingMessage && resultMessage && (
            <div className="w-full px-3 text-center">
              {resultMessage}
            </div>
        )}
      </form>
    </div>
  )
}

export default Contact2