import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaPhone } from 'react-icons/fa';

const Footer2 = ({data}) => {

  return (
    <div className='flex flex-col-reverse md:flex-row justify-between items-center background-gradiente-blu px-5 py-2 w-screen'>
        <div>
            <span className='text-white text-xs font-light'>{data.textinfo1} </span>
        </div>
        <div>
            <span className='text-white text-xs font-light'>{data.textinfo2} </span>
        </div>
        <div className='flex flex-row gap-2 items-center'>
          <div className="bg-[#0f95d4] p-2 rounded-full inline-flex items-center justify-center">
              <FaPhone className="text-white w-2 h-2 md:w-4 md:h-4" />
          </div>
          <span className='text-white text-sm font-light'>{data.textinfo3} </span>
        </div>
        <div className='flex flex-col md:flex-row gap-5'>
          <Link to='/blog'>
            <button className='rounded-3xl py-2 border border-white text-white font-light w-20 h-8 text-xs hover:bg-white hover:text-[#0f95d4]'>{data.buttontext1}</button>
          </Link>
        </div>
    </div>
  )
}

export default Footer2