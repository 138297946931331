import React from 'react';
import NavigationBar2 from '../components/NavigationBar2';
import Header2 from '../components/Header2';
import Hero2 from '../components/Hero2';
import Highlights2 from '../components/Highlights2';
import Footer2 from '../components/Footer2';
import Service2 from '../components/Service2';
import BlogArticles2 from '../components/BlogArticles2';
import AllArticles from '../components/AllArticles';
import Contact2 from '../components/Contact2';
import SinglePost2 from './SinglePost2';
import Parallax2 from '../components/Parallax2';

const DynamicComponent = ({ componentData }) => {
    switch (componentData.component) {
        case 'NavigationBar':
            return <NavigationBar2 data={componentData} />;
        case 'Header':
            return <Header2 data={componentData} />;
        case 'Hero':
            return <Hero2 data={componentData} />;
        case 'Highlights':
            return <Highlights2 data={componentData} />;
        case 'Service':
            return <Service2 data={componentData} />;
        case 'Parallax':
            return <Parallax2 data={componentData} />;
        case 'BlogArticles':
            return <BlogArticles2 data={componentData} />;
        case 'AllArticles':
            return <AllArticles data={componentData} />;
        case 'Contact':
            return <Contact2 data={componentData} />;
        case 'SinglePost2':
            return <SinglePost2 data={componentData} />;
        case 'Footer':
            return <Footer2 data={componentData} />;
        default:
            return null;
    }
};

export default DynamicComponent;
