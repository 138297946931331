import React, {useState, useEffect} from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';


const Service2 = ({data}) => {
    const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    const apiKey = process.env.REACT_APP_API_KEY_NEW;
    const url = `${serverBaseUrl}/content/tree/Services`;
    const imageUrl = process.env.REACT_APP_IMAGE_URL;
  
    const [services, setServices] = useState([]);

    console.log('DATALLARTICLES', data)
  
    console.log(services)
  
  useEffect(() => {
    const fetchData = async () => {
  
      try {
        const response = await axios.get(url, {
          headers: {
            "api-key": apiKey
          }
        });
        console.log(response);
        setServices(response.data)
      } catch (error) {
        console.error("There was an error fetching the data", error);
      }  
    };
  
    fetchData();
  }, [])

  /*function extractTextFromHtml(htmlString) {
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = htmlString;
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }*/
  
  
  return (
    <>
        <div id='servizi' className='px-10 py-10 pt-10 pb-5 bg-slate-100'>
            <h2 className='text-3xl text-[#0f95d4] text-center font-bold mb-5'>{data.mainTitle}</h2>
            <div className='flex flex-col md:flex-row md:flex-wrap justify-center gap-5'>
                {services && services.map((service) => (
                <div key={service._id} className='md:w-1/5 flex flex-col items-center p-4 bg-white rounded-lg shadow-sm'>
                    <img src={`${imageUrl}${service.serviceImmagine.path}`} className='w-20 mb-2' alt="Service"/>
                    <h3 className='font-bold text-sm text-center pb-2'>{service.serviceTitle}</h3>
                    <p className='font-light text-xs text-center pt-2 text-stone-500' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(service.serviceDescription)}}></p>
                </div>
                ))}
            </div>
         </div>
    </>
  )
}

export default Service2