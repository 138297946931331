import React from 'react';
import { Link } from 'react-router-dom';
import { FaPhone } from 'react-icons/fa';

const NavigationBar2 = ({data}) => {

    const imageUrl = process.env.REACT_APP_IMAGE_URL;
    
  return (
    <div className='flex flex-row justify-between items-center background-gradiente-blu px-10 py-5 w-screen'>
        <div >
          <Link to='/'>
            <img className='w-24 h-10 md:w-60 md:h-20 object-contain' src={`${imageUrl}${data.image?.path}`} />
          </Link>
        </div>
        <div className='flex flex-row justify-center gap-5 items-center'>
        {data._children && data._children.map((nav) => (
            <Link key={nav._id} to={nav.slug}>
              <p className='font-light text-sm text-center pt-2 text-white'>{nav.textinfo1}</p>
            </Link>
        ))}
          </div>
        <div className='flex flex-row gap-2 items-center'>
          <div className="bg-[#0f95d4] p-2 rounded-full inline-flex items-center justify-center">
              <FaPhone className="text-white w-2 h-2 md:w-4 md:h-4" />
          </div>
          <span className='text-white text-sm font-light'>{data.textinfo1} </span>
        </div>
    </div>
  )
}

export default NavigationBar2