import React from 'react'
import DOMPurify from 'dompurify';

const Parallax2 = ({data}) => {
    const imageUrl = process.env.REACT_APP_IMAGE_URL;
    
    /*function extractTextFromHtml(htmlString) {
      const tempDivElement = document.createElement("div");
      tempDivElement.innerHTML = htmlString;
      return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    const dataBody = extractTextFromHtml(data.body)*/
     const cleanHtml = DOMPurify.sanitize(data.body);

  return (
    <>
      <div
        className=' text-white'
        style={{
          background: `linear-gradient(to right, rgba(132, 200, 232, 0.7), rgba(15, 149, 212, 0.7)), url(${imageUrl}${data.backgroundImage.path})`,
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className='py-10 px-10 flex flex-col md:flex-row justify-between w-screen items-center'>
          <div className='max-w-xl'>
              <h1 className='text-xl font-bold'>{data.mainTitle}</h1>
              <p className='text-md mt-4' dangerouslySetInnerHTML={{__html: cleanHtml}}></p>
          </div>
          <div>
              <img className='w-36 h-36 md:w-40 md:h-40 object-contain' src={`${imageUrl}${data.image.path}`}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default Parallax2